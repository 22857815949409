<template>
  <div :class='`${$route.name}_main-ape`'>
    <el-menu v-if='showInnerMenu' :default-active='routePath' :background-color='`#fff`' :text-color='`#000`'
             :active-text-color='`#ff3176`' class='el-submenu-header'
             mode='horizontal'
             @select='handleMenuSelect'>
      <el-menu-item v-for='(menu,key) in menus' :key='key' :index='menu.url'
                    :disabled='userPermissions.indexOf(menu.permission_name) === -1||menu.disabled'>
        <svg-icon :fill="'currentColor'" :class='`iconfont`' style='display: inline-block'
                  :icon-class="menu['icon']"></svg-icon>
        <span>{{ menu.display_name }}</span>
      </el-menu-item>
    </el-menu>
    <transition name='el-fade-in-linear'>
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ApeMenu',
  computed: {
    ...mapGetters(['routePath', 'userPermissions', 'routePath']),
    showInnerMenu() {
      return this.$route.meta.hideInnerMenu === undefined ? true : !(this.$route.meta.hideInnerMenu)
    }
  },
  props: {
    menus: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {}
  },
  methods: {
    handleMenuSelect(routePath) {
      if (routePath)
        this.$router.push(routePath)
    }
  }
}
</script>

<style scoped>
.el-menu-item {
  font-size: 14px !important;
  height: 40px !important;
  line-height: 40px !important;
  padding-right: 5px !important;
  padding-left: 5px !important;
  /*margin-bottom: 1px !important;*/
}

/*.el-menu-item li {*/
/*  border: #fe346e 1px dashed !important;*/
/*}*/
</style>
